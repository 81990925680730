<template>
  <div>
    <Navbar page="Gestão de requisições" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <h3 v-if="!dpo" class="text-lg font-semibold my-3 mb-4">Empresa sem DPO selecionado <small class="text-red-700">(Para gestão de requisição é obrigatório o cadastro de DPO)</small></h3>
      <div class="grid grid-cols-12 gap-x-6 gap-y-2 mb-3">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link to="/requisicao/gerenciar" 
            type="button"
            class="transition duration-200 bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-700 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full"
            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
          >
            <span class="inline-block mr-2">Editar plugin de URLs</span>
          </router-link>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="$modal.show('novaRequisicao')"
            type="button"
            class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full"
          >
            <span class="inline-block mr-2">Gerar requisição manual</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link to="/entregaveis/especifico/requisicao" 
            type="button"
            class="transition duration-200 bg-yellow-500 hover:bg-yellow-400 focus:bg-yellow-700 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full"
          >
            <span class="inline-block mr-2">Relatórios de requisição</span>
          </router-link>
        </div>
        <!-- <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="getCodigo"
            type="button"
            class="transition duration-200 bg-blue-300 hover:bg-blue-400 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center w-full"
          >
            <span class="inline-block mr-2">Plugin para o site</span>
          </button>
        </div> -->
        <!-- <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link to="/requisicao/direitos" type="button" class="transition duration-200 bg-indigo-500 hover:bg-indigo-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Tipos de direito</span>
          </router-link>
        </div> -->
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="$modal.show('importarModal')" class="transition duration-200 bg-green-500 hover:bg-green-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            Importar requisições 
          </button>
        </div>
        <!-- <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <router-link to="/requisicao/config" type="button" class="transition duration-200 bg-gray-500 hover:bg-gray-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Configuração Plugin</span>
          </router-link>
         </div> -->
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="excluirSelecionadas" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Arquivar selecionadas</span>
          </button>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 xl:col-span-4 mb-1 mt-1">
          <input
            v-model="busca"
            type="text"
            name="busca"
            id="busca"
            placeholder="Localizar requerente"
            class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
          />
        </div>

        <div class="col-span-12 xl:col-span-4 mb-1 mt-1">
          <select v-model="status" name="status" id="status" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Filtrar por status</option>
              <option value="Pendente" class="text-yellow-600">Pendente</option>
              <option value="Atrasado" class="text-red-700">Atrasado</option> 
              <option value="Em analise" class="text-blue-700">Em análise</option> 
              <option value="Respondido" class="text-green-700">Respondido</option>
          </select>
        </div>
        
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
         
          <button
            @click="start"
            class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            Buscar
          </button>
        </div>
        <div class="col-span-12">
          <label for="arquivadas" class="text-sm mr-2">
          <input @change="start" v-model="arquivadas" type="checkbox" id="arquivadas" class="rounded-sm">
          Listar arquivadas
          </label>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50 ">
                  <tr class="text-xs tracking-wider text-center">
                    <th class="px-3 py-3 text-left text-gray-500 cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                    </th>
                    <th class="px-6 py-3"
                    >
                      Protocolo
                    </th>
                    <th class="px-6 py-3"
                    >
                      Requerente
                    </th>
                    <th class="px-6 py-3"
                    >
                      Direito requerido
                    </th>
                    <th class="px-6 py-3"
                    >
                      Entrada
                    </th>
                    <th class="px-6 py-3"
                    >
                      Prazo
                    </th>
                    <th class="px-6 py-3"
                    >
                      Status
                    </th>
                    <th class="px-6 py-3"
                    >
                      Data Resposta
                    </th>
                    <th class="px-6 py-3"
                    >
                      URL
                    </th>
                    <th class="px-6 py-3"
                    >
                      Opção
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" class="text-sm text-gray-500 text-center">
                    <td class="px-3 py-4 whitespace-nowrap">
                      <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                    </td>
                    
                    <td class="px-6 py-4 whitespace-nowrap">
                        {{ item.protocolo ? item.protocolo : "" }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                          {{ item.nome }}
                    </td>

                    <td class="px-6 py-4">
                        {{ item.direito ? item.direito.direito : "" }}
                    </td>

                    <td class="px-6 py-4 text-center">
                      <div v-if="item.data" class="text-sm text-gray-500">
                        {{ item.data | moment("DD/MM/YYYY HH:mm") }}
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div 
                          v-if="item.data" 
                          :class="{
                            'text-yellow-500': item.status === 'Pendente',
                            'text-red-500': item.status === 'Atrasado',
                            'text-blue-500': item.status === 'Em analise',
                            'text-green-600': item.status === 'Respondido',
                            'text-black': !item.status,
                          }"
                        >
                          {{getDiff(item.data, item.dataFim)}}
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div
                        class="text-sm font-semibold text-pendente"
                        :class="{
                          'text-yellow-500': item.status === 'Pendente',
                          'text-red-500': item.status === 'Atrasado',
                          'text-blue-500': item.status === 'Em analise',
                          'text-green-600': item.status === 'Respondido',
                          'text-black': !item.status,
                        }"
                      >
                        {{ item.status ? item.status : "" }}
                      </div>
                    </td>

                    <td class="px-6 py-4 text-center">
                      <div v-if="item.dataFim" class=" font-semibold text-green-600">
                        {{ item.dataFim | moment("DD/MM/YYYY HH:mm")}}
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        {{ item.configRequisicao ? item.configRequisicao.url : ''}}
                    </td>

                    <td class="px-6 py-4 text-sm font-medium">
                      <router-link
                        :to="`/${route}/analisar/${item._id}`"
                        @click="remove(item._id)"
                        type="button"
                        class="text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1 mt-1"
                      >
                        Analisar
                      </router-link>

                      <!-- <button
                        v-if="!arquivadas"
                        @click="remove(item._id)"
                        type="button"
                        class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1 mt-1"
                      >
                        Arquivar
                      </button> -->
                      <button
                        v-if="arquivadas"
                        @click="ativar(item._id)"
                        type="button"
                        class="text-white hover:bg-gray-800 bg-gray-700 rounded py-1 px-4 mx-1 mt-1"
                      >
                        Ativar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              v-model="page"
              :per-page="perPage"
              :records="total"
              @paginate="setPage"
              class="pagination"
            />
          </div>
        </div>
      </div>


      <modal name="novaRequisicao" :width="800" :adaptive="true" :height="'auto'" :clickToClose="false">
        <div class="px-3 py-4">
          <h2 class="text-2xl font-bold mb-3">Nova Requisição de titular</h2>
          
          <div class="grid grid-cols-12 gap-3">
            <div class="col-span-12 md:col-span-4">
              <label for="url_configRequisicao" class="block text-sm font-medium">URL</label>
              <select required v-model="form.configRequisicao" name="url_configRequisicao" id="url_configRequisicao" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option v-for="item in urlRequisicao" :key="item._id" :value="item._id">{{ item.url}}</option>
              </select>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-3 my-1">

            <div class="col-span-12 md:col-span-4">
              <label for="tipoTitular" class="block text-sm font-medium">Tipo do titular</label>
              <select required v-model="form.tipoTitular" name="tipoTitular" id="tipoTitular" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option v-for="tipo in tiposTitulares" :key="tipo._id" :value="tipo._id">{{tipo.tipo}}</option>
              </select>
            </div>

            <div class="col-span-12 md:col-span-4">
              <label for="solicitacao" class="block text-sm font-medium">Solicitação</label>
              <select required v-model="form.solicitacao" name="solicitacao" id="solicitacao" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                  <option v-for="solicitacao in direitos" :key="solicitacao._id" :value="solicitacao.ref">{{ solicitacao.direito }}</option>
              </select>
            </div>

            <div class="col-span-12 md:col-span-4">
              <label for="origem" class="block text-sm font-medium">Origem</label>
              <select required v-model="form.origem" name="origem" id="origem" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option value="Site">Site</option>
                <option value="Email">Email</option>
                <option value="Telefone">Telefone</option>
                <option value="Pessoalmente">Pessoalmente</option>
                <option value="Interna">Interna</option>
                <option value="Outro">Outro</option>
              </select>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-3 mt-3">
            <div class="col-span-12 md:col-span-5">
                <label for="nome" class="block text-sm font-medium">Primeiro e último nome</label>
                <input type="text" v-model="form.nome" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div> 

            <div class="col-span-12 md:col-span-3">
                <label for="cpf_cnpj" class="block text-sm font-medium">3 últimos digitos do CPF</label>
                <input type="text" v-model="form.cpf_cnpj" name="cpf_cnpj" id="cpf_cnpj" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div> 

            <div class="col-span-12 md:col-span-4">
                <label for="telefone" class="block text-sm font-medium">Telefone</label>
                <input type="text" v-model="form.telefone" name="telefone" id="telefone" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div>
          </div>
          <div class="grid grid-cols-12 gap-3 mt-3">
            <div class="col-span-12 md:col-span-6">
                <label for="email" class="block text-sm font-medium">Email</label>
                <input type="text" v-model="form.email" name="email" id="email" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div>  
          </div>
          <div class="grid grid-cols-12 gap-3 mt-3">
            <div class="col-span-12">
              <label for="descricao" class="block text-sm font-medium">Detalhes da solicitação</label>
              <textarea v-model="form.descricao" type="text" name="descricao" id="descricao" rows="3" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
          </div>
          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('novaRequisicao')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
            <div class="text-right">
                <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                    Salvar
                </button>
            </div>
          </div>
        </div>
      </modal>

      <modal name="pluginSite" :adaptive="true" :height="'auto'">
        <div class="px-5 py-8">
          <h2 class="text-2xl font-bold mb-5">Plugin para o site</h2>
          <div class="grid grid-cols-12 gap-4">

            <div class="col-span-12">
              <label for="codigo" class="block text-sm font-medium">Copie o código abaixo e cole <b class="text-blue-800">no final da body html</b> do seu site para gerenciamento de solicitações de titulares e canal de denúncia de incidentes.</label>
              <textarea v-model="codigo" type="text" name="codigo" id="codigo" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
            
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('pluginSite')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
          </div>
        </div>
      </modal>

      <modal name="importarModal" :adaptive="true" :height="'auto'">
        <div class="px-5 py-5">
          <h2 class="text-xl mb-2 font-bold mb-2"> Importar base</h2>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
                <a target= "_blank" rel="noreferrer" :href="`/arquivo-modelo-15.csv`" class="text-sm underline">Exemplo arquivo modelo importação</a>
            </div>
            <div class="col-span-12">
              <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update">
              </vue-dropzone>
            </div>
          </div>
        </div>
      </modal>

    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
  components: {
    vueDropzone
  },
  data() {
    return {
      route: "requisicao",
      form: {
        solicitacao: '',
        nome: '',
        cpf_cnpj: '',
        email: '',
        telefone: '',
        origem: 'Site',
        descricao: '',
        tipoTitular: '',
        configRequisicao: '',
      },
      titulares: [],
      atividades: [],
      tiposTitulares: [],
      urlRequisicao: [],
      direitos: [],
      list: [],
      dpo: null,
      selecionados: [],
      page: 1,
      perPage: 10,
      busca: "",
      status: null,
      arquivadas: false,
      codigo: "",
      total: 0,
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/requisicao/importar-csv`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      }),
    };
  },
  methods: {
    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, {
        busca: this.busca,
        limit: this.perPage,
        arquivadas: this.arquivadas,
        status: this.status,
      });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
      this.codigo = listReq.data.codigo;
      
      const direitosReq = await this.$http.post(`/v1/direitos/list`, {});
      this.direitos = direitosReq.data.data;

      const reqgetDPO = await this.$http.post(`/v1/${this.route}/getDPO`);
      this.dpo = reqgetDPO.data;
    },

    getDiff(dataInicial, dataResposta) {
      const data_inicio = new Date(dataInicial);
      const data_resposta = new Date(dataResposta);
      const prazo = 15;
      if (dataResposta) {
          const diferencaEmMilissegundos = data_resposta - data_inicio;
          const diasPassados = diferencaEmMilissegundos / (1000 * 3600 * 24);
          const diasFaltando = prazo - diasPassados;
          return Math.floor(diasFaltando);
      }
      const qtd = this.$moment(dataInicial).add(15, 'days').diff(this.$moment(),"days");
      return qtd;
    },

    async excluirSelecionadas(){

      if(this.selecionados.length === 0){
        return this.$vToastify.warning("Nenhuma requisição selecionada.");
      }

      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Removido com sucesso!");
          this.start();
        }
      })
    },

    async ativar(id) {
      await this.$http.put(`/v1/${this.route}/`, { _id: id, ativo: true });
      this.$vToastify.success("Ativada com sucesso!");
      this.start();
    },

    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Arquivado com sucesso!");
      this.start();
    },

    async save() {
      const req = await this.$http.post(`/v1/${this.route}`, this.form);
      if (req.data.success) {
          this.$vToastify.success("Gerado com sucesso!");
          this.$modal.hide('novaRequisicao')
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async getCodigo() {
      const req = await this.$http.post(`/getCodigoPlugin`);
      this.codigo = req.data;
      this.$modal.show('pluginSite');
    },

    async selecionarTodos(){
      if (this.selecionados.length) {
        this.selecionados = [];
      } else {
        const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, busca: this.busca });
        this.selecionados = listReq.data.data;
      }
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { limit, skip });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    send(file, xhr, formData) {
      formData.append('empresa', this.$store.state.empresa && this.$store.state.empresa._id ? this.$store.state.empresa._id : null);
    },

    update(file, response) {
      console.log("response", response);
      if(response.success) this.$vToastify.success(response.msg);
      else this.$vToastify.error(response.err);
      this.$modal.hide('importarModal');
      this.start();
    },

  },
  async beforeMount() {
    this.start();

    const titularesReq = await this.$http.post(`/v1/titulares/list`, { all: true });
    this.titulares = titularesReq.data.data;

    const reqtipos = await this.$http.post('/v1/tipotitulares/list', { all: true });
    this.tiposTitulares = reqtipos.data.data;

    const reqUrls = await this.$http.post('/v1/requisicao/gerenciar/list', { all: true });
    this.urlRequisicao = reqUrls.data.itens;
    
  },
};
</script>
